import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import { AppRouter } from '../../../backend/services/_leadApi/trpc'
import superjson from 'superjson'

const LEAD_SERVICE_API_URL = process.env.REACT_APP_LEAD_API_URL || ''

export const client = () => {
  // @ts-ignore
  return createTRPCProxyClient<AppRouter>({
    // @ts-ignore
    transformer: superjson,
    links: [
      httpBatchLink({
        url: LEAD_SERVICE_API_URL,
      }),
    ],
  })
}
