import { db } from 'services/firebase'
import {
  ref,
  set,
  remove,
  get,
  update,
  onValue,
  onDisconnect,
  child,
} from 'firebase/database'
import { client } from 'services/trpcClient'
import { getToken } from 'services/getToken'
import { auth } from 'services/firebase'

export const initGame = async (context: any, event: any) => {
  const playerId = auth.currentUser?.uid
  const token = await getToken()
  const { gameId } = context

  try {
    const reference = ref(db, `games/${context.gameId}/users/` + playerId)
    const archiveReference = ref(
      db,
      `games/${context.gameId}/archiveUsers/` + playerId
    )
    set(reference, {
      username: playerId,
      connectedAt: new Date().getTime(),
    })

    set(archiveReference, {
      username: playerId,
      connectedAt: new Date().getTime(),
    })

    const usersSnapshot = await get(ref(db, `games/${context.gameId}/users/`))

    const data = usersSnapshot.val()
    const amount = data ? Object.keys(data).length : 0

    return { amount }

    // onValue(reference, (snapshot) => {
    //   const data = snapshot.val()
    //   const amount = data ? Object.keys(data).length : 0
    //   return { amount }
    // })
  } catch (err) {
    throw err
  }
}

export const startGame = async (context: any, event: any) => {
  const token = await getToken()
  const { gameId } = context

  try {
    await client(token).joinUserOnGame.mutate({
      gameId,
      timestamp: Date.now(),
    })
  } catch (err) {
    throw err
  }
}

export const userFinishGame = async (context: any, event: any) => {
  const token = await getToken()
  const { gameId } = context
  const playerId = auth.currentUser?.uid

  try {
    const reference = ref(db, `/games/${context.gameId}/users/` + playerId)
    remove(reference)

    const archiveReference = ref(
      db,
      `games/${context.gameId}/archiveUsers/` + playerId
    )
    update(archiveReference, {
      username: playerId,
      disconnectedAt: new Date().getTime(),
    })

    await client(token).createEvent.mutate({
      gameId,
      event: 'User finish game',
      timestamp: Date.now(),
    })
  } catch (err) {
    throw err
  }
}

export const userPingGame = async (context: any) => {
  const token = await getToken()
  const { gameId } = context
  const playerId = auth.currentUser?.uid

  try {
    const reference = ref(db, `/games/${context.gameId}/users/` + playerId)
    remove(reference)

    const archiveReference = ref(
      db,
      `games/${context.gameId}/archiveUsers/` + playerId
    )
    update(archiveReference, {
      username: playerId,
      pingAt: new Date().getTime(),
    })

    await client(token).createEvent.mutate({
      gameId,
      event: 'User ping game',
      timestamp: Date.now(),
    })
  } catch (err) {
    throw err
  }
}

export const userWinGame = async (context: any, event: any) => {
  const playerId = auth.currentUser?.uid
  const token = await getToken()
  const { gameId } = context

  try {
    const reference = ref(db, `/games/${context.gameId}/users/` + playerId)
    remove(reference)
    await client(token).createEvent.mutate({
      gameId,
      event: 'User Win',
      timestamp: Date.now(),
    })
  } catch (err) {
    throw err
  }
}

export const userLooseGame = async (context: any, event: any) => {
  const playerId = auth.currentUser?.uid
  const token = await getToken()
  const { gameId } = context

  try {
    const reference = ref(db, `/games/${context.gameId}/users/` + playerId)
    remove(reference)
    await client(token).createEvent.mutate({
      gameId,
      event: 'User Loose',
      timestamp: Date.now(),
    })
  } catch (err) {
    throw err
  }
}

export const createUserAfterConfirmation = async (event: any) => {
  const { email } = event
  const token = await getToken()
  try {
    await client(token).createUserAfterConfirmation.mutate({ email })
  } catch (err) {
    throw err
  }
}

export const getAvailableGame = async (context: any, event: any) => {
  const token = await getToken()
  try {
    //@ts-ignore
    const result = await client(token).getAvailableGame.query()
    return result
  } catch (err) {
    throw err
  }
}

export const createGameEvent = async (context: any, event: any) => {
  const { eventType } = event
  const token = await getToken()
  const { gameId } = context
  try {
    await client(token).createEvent.mutate({
      gameId,
      event: eventType,
      timestamp: Date.now(),
    })
  } catch (err) {
    throw err
  }
}

export const joinUserOnGame = async (event: any) => {
  const { gameId } = event
  const token = await getToken()
  try {
    await client(token).joinUserOnGame.mutate({ gameId, timestamp: Date.now() })
  } catch (err) {
    throw err
  }
}
