import { initializeApp } from 'firebase/app'
import {
  getAuth,
  indexedDBLocalPersistence,
  setPersistence,
} from 'firebase/auth'
import { getDatabase, ref } from 'firebase/database'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCQ8fMdxpDPtEBDaKyrCrIPG8_BOVw9enw',
  authDomain: 'touch-game-prod.firebaseapp.com',
  databaseURL:
    'https://touch-game-prod-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'touch-game-prod',
  storageBucket: 'touch-game-prod.appspot.com',
  messagingSenderId: '861438727701',
  appId: '1:861438727701:web:a9aecb981a5e04e892a01c',
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
export const auth = getAuth(firebaseApp)
setPersistence(auth, indexedDBLocalPersistence)
export const db = getDatabase()
