/* eslint-disable @typescript-eslint/no-unused-vars */
import { auth } from 'services/firebase'
import { sendSignInLinkToEmail, signInWithCustomToken } from 'firebase/auth'
import { getAuth } from 'firebase/auth'
import { client } from 'services/leadTrcpClient'
import { getToken } from 'services/getToken'
import { client as homeClient } from 'services/trpcClient'

import { onAuthStateChanged } from 'firebase/auth'

//@ts-ignore
export const checkIsAuth = () => async (callback) => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      callback({ type: 'AUTHENTICATED' })
    } else {
      callback({ type: 'NOT_AUTHENTICATED' })
    }
  })
}

export const signOut = async () => {
  try {
    // await auth.signOut()
    window.location.href = '/'
  } catch (err) {
    throw err
  }
}

export const getUserInfo = async () => {
  try {
    const userInfo = await auth.currentUser
    return userInfo
  } catch (err) {
    throw err
  }
}

//@ts-ignore
export const checkGameAvailability = async (context, event) => {
  const { email } = event.values
  try {
    const gameIdInvitation = window.localStorage.getItem('gameIdInvitation')

    if (gameIdInvitation === 'undefined' || !gameIdInvitation) {
      return true
    }

    const isGameAvailable = await client().checkGameAvailability.query({
      gameId: gameIdInvitation,
      email,
    })

    return isGameAvailable
  } catch (err) {
    throw err
  }
}

//@ts-ignore
export const signUp = async (context, event) => {
  const { email, name } = context.signUpValues
  try {
    return await client().signUpUser.query({
      email,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

//@ts-ignore
export const resendingOptCode = async (context, event) => {
  const { userEmail } = context

  try {
    const result = await client().resendVerificationCode.query({
      email: userEmail,
    })

    return { codeId: result.codeId }
  } catch (err) {
    console.log(err)
    throw err
  }
}

//@ts-ignore
export const signIn = async (context, event) => {
  const { email } = event.values
  try {
    return await client().signInUser.query({
      email,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

//@ts-ignore
export const checkVerificationCode = async (context, event) => {
  const { code } = event
  const { userEmail, verificationCodeId } = context
  try {
    const token = await client().checkVerificationCode.mutate({
      email: userEmail,
      code,
      codeId: verificationCodeId,
    })
    return token
  } catch (err) {
    console.log(err)
    throw err
  }
}

//@ts-ignore
export const signInWithCustomTokenHandler = async (context, event) => {
  const { customToken } = context

  try {
    await signInWithCustomToken(auth, customToken)
    return 'success'
  } catch (err) {
    console.log(err)
    throw err
  }
}

//@ts-ignore
export const registerUser = async (context, event: any) => {
  const { signUpValues } = context

  const token = await getToken()
  try {
    await homeClient(token).registerUser.mutate({
      email: signUpValues.email,
      name: signUpValues.name,
      address1: signUpValues.address1,
      address2: signUpValues.address2,
      phone: signUpValues.phone,
    })
  } catch (err) {
    throw err
  }
}
