import React, { useState, useEffect, Suspense, useContext } from 'react'
import {
  useNavigate,
  useLocation,
  Routes,
  Route,
  Navigate,
  redirect,
} from 'react-router-dom'
import { useActor } from '@xstate/react'
import { GlobalStateContext } from 'services/AuthService'
import { gameMachine } from 'machines/gameMachine'
import { createActorContext } from '@xstate/react'
import * as _ from 'lodash'
import useVH from 'react-viewport-height'
import { Provider, ErrorBoundary } from '@rollbar/react'

const rollbarConfig = {
  accessToken: '73a858d137df4dbaa8c2272125988a45',
  environment: 'testenv',
}

import './App.css'

const RegistrationPage = React.lazy(() => import('pages/RegistrationPage'))
const HomePage = React.lazy(() => import('pages/HomePage'))

export const GameMachineContext = createActorContext(gameMachine)

const Loading = () => (
  <div
    style={{
      position: 'absolute',
      left: '50%',
      top: '50%',
    }}
  >
    Loading
  </div>
)

const App = () => {
  const [isMobile, setIsMobile] = useState(false)
  const globalService = useContext(GlobalStateContext)
  const [authMachine, sendToAuthMachine] = useActor(globalService.authService)
  const checkingIsAuth =
    authMachine.matches('checkingIsAuth') || authMachine.matches('init')

  const { initialPath } = authMachine.context

  const navigate = useNavigate()

  const location = useLocation()

  useEffect(() => {
    handleResize()
    sendToAuthMachine({ type: 'INIT', initialPath: location.pathname })
  }, [])

  //@ts-ignore
  // setInitialPath(location.pathname)

  useEffect(() => {
    const playGame = location.pathname.split('/')[1]
    const isPlayGame = playGame === 'playgame' ? true : false
    if (isPlayGame) {
      const gameId = location.pathname.split('/')[2]
      window.localStorage.setItem('gameIdInvitation', gameId)
      navigate('/register')
    }
  })

  const isAuthenticated = authMachine.matches('authenticated')

  // const { initialPath } = authMachine.context

  // console.log(authMachine.value)

  // const navigate = useNavigate()

  useEffect(() => {
    const urlPathname = window.location.pathname
    const urlHref = window.location.href

    console.log('URL Href: ', urlHref)
    // sendToAuthMachine({ type: 'INIT', initialPath: urlPathname })

    const isConfirmation = urlHref.includes('/confirm?apiKey')

    if (isConfirmation) {
      window.localStorage.setItem('confirmationHref', urlHref)
    }

    // if (!initialPath && _.startsWith(urlPathname, '/auth')) {
    //   console.log('should')
    //   //@ts-ignore
    //   setInitialPath(urlPathname)
    // }
  }, [])

  const handleResize = () => {
    if (/Android|iPhone/i.test(navigator.userAgent)) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
    // if (window.innerWidth < 720) {
    //   if (/Android|iPhone/i.test(navigator.userAgent)) {
    //     setIsMobile(true)
    //   } else {
    //     setIsMobile(false)
    //   }
    // } else {
    //   setIsMobile(false)
    // }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home')
    } else {
      console.log('INIT: ', initialPath)
      //@ts-ignore
      if (initialPath?.includes('/home')) {
        navigate('/')
      } else {
        //@ts-ignore
        navigate(initialPath)
      }
    }
  }, [checkingIsAuth])

  useVH()

  return (
    <div
      className="viewport"
      style={{
        // height: '100vh',
        height: 1,
      }}
    >
      <div className="h-full w-screen grid items-center justify-items-center bg-light-green">
        {isMobile ? (
          <Suspense fallback={<Loading />}>
            {checkingIsAuth ? (
              <div
                style={{
                  display: 'grid',
                  justifyItems: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                Loading...
              </div>
            ) : (
              <>
                <Routes>
                  <Route
                    path="/*"
                    element={
                      <RegistrationPage
                        machine={authMachine}
                        sendToMachine={sendToAuthMachine}
                      />
                    }
                  />
                  <Route path="/home/*" element={<HomePage />} />
                  {/* <Route
                  path="/playgame/*"
                  element={() => {
                    ;<Navigate to="/signup" />
                  }}
                /> */}
                </Routes>
              </>
            )}
          </Suspense>
        ) : (
          <div>Please switch to mobile </div>
        )}
      </div>
    </div>
  )
}

export default App
