import React, { createContext } from 'react'
import { useInterpret } from '@xstate/react'
import { authMachine } from 'machines/authMachine'
import { ActorRefFrom } from 'xstate'

interface GlobalStateContextType {
  authService: ActorRefFrom<typeof authMachine>
}

export const GlobalStateContext = createContext(
  // Typed this way to avoid TS errors,
  // looks odd I know
  {} as GlobalStateContextType
)

//@ts-ignore
export const GlobalStateProvider = (props) => {
  const authService = useInterpret(authMachine)

  return (
    <GlobalStateContext.Provider value={{ authService }}>
      {props.children}
    </GlobalStateContext.Provider>
  )
}
