import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import { AppRouter } from '../../../backend/services/_webApi/trpc'
import superjson from 'superjson'

const WEB_SERVICE_API_URL = process.env.REACT_APP_WEB_SERVICE_API_URL || ''

// @ts-ignore
export const client = (token?) => {
  // @ts-ignore
  return createTRPCProxyClient<AppRouter>({
    // @ts-ignore
    transformer: superjson,
    links: [
      httpBatchLink({
        url: WEB_SERVICE_API_URL,
        headers() {
          return {
            Authorization: `Bearer ${token}`,
          }
        },
      }),
    ],
  })
}
