import { client } from 'services/leadTrcpClient'

//@ts-ignore
export const createLead = async (context, event) => {
  const { name, email } = event.values
  try {
    const result = await client().createLead.mutate({ name, email })
    return result
  } catch (err) {
    console.log(err)
    throw err
  }
}

//@ts-ignore
export const checkVerificationCodeAndSignUp = async (context, event) => {
  const { code } = event
  const { verificationCodeId, userEmail } = context
  const { name, phone } = context.signUpValues

  try {
    const result = await client().checkVerificationCodeAndSignup.mutate({
      name,
      email: userEmail,
      code,
      codeId: verificationCodeId,
      phone,
    })
    return result
  } catch (err) {
    throw err
  }
}
